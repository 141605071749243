import { Text } from '@chakra-ui/react';
import * as React from 'react';
import { getErrorMessage } from '../../utils/util';

function ErrorStateMsg({ error }: { error: Error }) {
  return (
    <Text
      width="80%"
      maxWidth={360}
      marginX="auto"
      paddingY={30}
      textAlign="center"
      fontSize="1.225em"
      fontStyle="italic"
      color="#A73636"
    >
      {getErrorMessage(error)}
    </Text>
  );
}

export default ErrorStateMsg;
