import React from 'react';
import Head from 'next/head';
import type { AppProps } from 'next/app';
import { GoogleAnalytics } from 'nextjs-google-analytics';
import { GoogleTagManager } from '@next/third-parties/google';
import { ChakraBaseProvider } from '@chakra-ui/react';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import BugsnagPerformance from '@bugsnag/browser-performance';

import {
  getFaviconPathByPartner,
  getTitleByPartner,
} from '../utils/partnerTheme';
import { theme } from '../components/pro-theme';
import Fonts from '../components/primitives/Fonts';
import { AuthProvider } from '../contexts/AuthContext';
import ConsentScreen from '../components/ConsentScreen';
import { BranchIoProvider } from '../contexts/branch-io-provider';
import ApolloClientProvider from '../contexts/ApolloClientProvider';

import '../../styles/globals.css';
import '../../styles/custom-datepicker.css';
import 'react-international-phone/style.css';
import '../../styles/mymtnpicknfix.webflow.css';
import { IntercomProvider } from 'react-use-intercom';
import { cookieStorage } from '../utils/CookieStore';
import ErrorStateMsg from '../components/error-state-msg';

const INTERCOM_APP_ID = 'nhkmwor5';

Bugsnag.start({
  apiKey: process.env.NEXT_PUBLIC_BUGSNAG_API_KEY || '',
  appVersion: '0.1.0',
  releaseStage: process.env.NEXT_PUBLIC_APP_ENV,
  plugins: [new BugsnagPluginReact()],
});
BugsnagPerformance.start({
  apiKey: process.env.NEXT_PUBLIC_BUGSNAG_API_KEY || '',
  appVersion: '0.1.0',
});

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React);

export default function App({ Component, pageProps }: AppProps) {
  return (
    <>
      <Head>
        <title>
          Comprehensive Device Insurance & Repair Services -{' '}
          {getTitleByPartner()}
        </title>
        <link
          rel="shortcut icon"
          href={`${getFaviconPathByPartner()}/favicon.ico`}
          type="image/x-icon"
        />
        <link rel="icon" href={`${getFaviconPathByPartner()}/favicon.ico`} />
        <link rel="manifest" href="/manifest.json" />

        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href={`${getFaviconPathByPartner()}/apple-touch-icon.png`}
        />
        <link
          rel="icon"
          type="image/icon"
          sizes="32x32"
          href={`${getFaviconPathByPartner()}/favicon-32x32.png`}
        />
        <link
          rel="icon"
          type="image/icon"
          sizes="16x16"
          href={`${getFaviconPathByPartner()}/favicon-16x16.png`}
        />
        <link
          rel="manifest"
          href={`${getFaviconPathByPartner()}/site.webmanifest`}
        />
        <link
          rel="mask-icon"
          href={`${getFaviconPathByPartner()}/safari-pinned-tab.svg`}
          color="##ffcb05"
        />
        <meta name="msapplication-TileColor" content="##ffcb05" />
        <meta name="theme-color" content="#ffffff" />
      </Head>
      <ErrorBoundary
        FallbackComponent={ErrorStateMsg}
        onError={(event: { originalError: Error }) => {
          const error = event.originalError;

          if (error instanceof Error) {
            const chunkFailedMessage = /Loading chunk [\d]+ failed/;

            if (error?.message && chunkFailedMessage.test(error.message)) {
              if (!cookieStorage.getWithExpiry('chunk_failed')) {
                cookieStorage.setWithExpiry('chunk_failed', 'true', 10000);
                window.location.reload();
              }
            }
          }
        }}
      >
        <GoogleAnalytics trackPageViews />
        <GoogleTagManager gtmId="GTM-P7JGWCKP" />
        <IntercomProvider appId={INTERCOM_APP_ID}>
          <ChakraBaseProvider theme={theme}>
            <Fonts />
            <ApolloClientProvider>
              <AuthProvider>
                <BranchIoProvider>
                  <Component {...pageProps} />
                </BranchIoProvider>
                <ConsentScreen />
              </AuthProvider>
            </ApolloClientProvider>
          </ChakraBaseProvider>
        </IntercomProvider>
      </ErrorBoundary>
    </>
  );
}
